/* styles.css */

.page {
    flex-direction: row;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 0vw 5vw;
    justify-content: center;
    align-items: center;

    background-repeat: no-repeat;
    background-size: 250%;
    background-position: -70vw;
    background-color: white;
}

.headingRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 1vw 0;
    /* height: auto; */
    align-items: center;
    margin: 1vw 0;
    /* background-color: rgba(0, 94, 146, 1); */
}

.logo {
    height: 6vw;
}

.headingText {
    font-size: 3.5vw;
    font-weight: bold;
}

.headingTextUp {
    font-size: 3.5vw;
    font-weight: bold;
}

.headingTextDown {
    font-size: 2.5vw;
    font-weight: 200;
    text-align: center;
}

.logo1 {
    width: 10vw;
}

.logo1VisOff {
    width: 10vw;
    height: 10vw;
    display: none;
}

.infoSec {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
}

.infoLabel {
    font-size: 1.5vw;
    border: 0.1vw solid rgba(0, 94, 146, 1);
}

.name,
.email {
    width: 45vw;
    display: flex;
    flex-direction: row;
    font-size: 1.5vw;
    align-items: center;
    padding: 0.5vw 0;
    border: 0.1vw solid rgba(0, 94, 146, 1) !important;
    color: rgba(0, 94, 146, 1);
}

.phoneNumber,
.subject,
.gender,
.age,
.height,
.weight {
    width: 15vw;
    display: flex;
    font-size: 1.5vw;
    justify-content: center;
    align-items: center;
    padding: 0.5vw 0;
    border: 0.1vw solid rgba(0, 94, 146, 1) !important;
    color: rgba(0, 94, 146, 1);
}

.vitalRows {
    display: flex;
    flex-direction: column;
    padding: 2vw 0;
    width: 100%;
}

.vitalsCol {
    display: flex;
    width: 100%;
    flex-direction: column;
    /* background-color: grey; */
    padding-top: 0.8vw;
    background-image: linear-gradient(rgba(255, 255, 255, 0.7),
            rgba(255, 255, 255, 0.7)),
        url("../Images/watermark.png");
        
}

.vital {
    width: 30vw;
    font-size: 1.5vw;
    font-weight: bold;
}

.value,
.units,
.range {
    width: 20vw;
    font-size: 1.5vw;
    /* background-color: rgba(0, 94, 146, 1); */
    /* background-color: red; */
    /* background-color: green; */
}

.watermark {
    position: absolute;
    min-width: 280%;
    min-height: 200%;
    display: block;
    height: 100%;
    width: 100%;
    opacity: 0.5;
}

.allbuttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.noteSec {
    display: flex;
    justify-content: center;
    font-size: 1.7vw;
    margin-top: 0.5vw;
}

.downloadBtn {
    display: flex;
    padding: 1vw 1vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
    width: 14vw;
    background-color: black;
    color: white;
    border-radius: 2vw;
    font-size: 1.5vw;
    align-self: center;
    cursor: pointer;
    justify-content: center;
    text-decoration: none;
    margin-left: 2vw;
    margin-right: 2vw;
    border: none;
}

.popparent {
    /* position: relative; */
}

.sharepopup {
    display: flex;
    flex-direction: column;
    /* background-color: rgb(128,128,128,1); */
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(8.7px);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1vw;
    width: 50vw;
    height: 20vw;
    z-index: 1000;
    justify-content: space-around;
    align-items: center;
}

.largecont {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    align-items: center;
    height: 60%;
    width: 90%;
    transition: all ease-in 2s;
    /* background-color: red; */
}

.wpimage {
    width: 6vw;
    height: 6vw;
}

.optimage {
    width: 4vw;
    height: 4vw;
}

.linktext {
    display: flex;
    width: 90%;
    justify-content: center;
}

.smallcont {
    display: flex;
    justify-content: center;
    height: 30%;
    width: 90%;
    text-align: start;
    /* background-color: yellow; */
}

.wpdiv {
    display: flex;
    position: relative;
    justify-content: center;
}

.copybtn {
    display: flex;
    width: 15vw;
    height: 4vw;
    background-color: black;
    color: wheat;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 3vw;
    font-size: 2vw;
}

.copyimage {
    display: flex;
    padding-top: 1vw;
    padding-bottom: 1vw;
    width: 3vw;
    margin-right: 1vw;
    justify-content: start;
}

.crossbtn {
    position: fixed;
    width: 2vw;
    top: -1vw;
    right: -1vw;
}

.sharebtn {
    display: flex;
    padding: 1vw 1vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
    justify-content: center;
    margin: 0;
    text-align: center;
    align-items: center;
    font-size: 1.5vw;
    border-radius: 5vw;
    border-color: transparent;
}

.sharebtn2 {
    display: flex;
    padding: 1vw 1vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
    justify-content: flex-start;
    margin: 0;
    text-align: center;
    align-items: center;
    font-size: 1.5vw;
    border-radius: 5vw;
    border-color: transparent;
}

.wpoptdiv {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* background-color: brown; */
}

.expandArrow {
    background-color: black;
    height: 7vw;
    transform: scaleY(1.3) translateY(10%);
    width: 0.5vw;
    margin-right: 2vw;
    margin-left: 0.8vw;
}

.expandArrowMail {
    background-color: black;
    height: 5vw;
    transform: scaleY(1.3) translateY(10%);
    width: 0.5vw;
    margin-right: 2vw;
    margin-left: 0.8vw;
}

.wpoptbtn {
    display: flex;
    padding: 1vw 1vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
    justify-content: center;
    margin-right: 1vw;
    margin-left: 1vw;
    text-align: center;
    align-items: center;
    font-size: 1.5vw;
    border-radius: 50%;
    border-color: transparent;
}

.vitalsTable {
    width: 100%;
    border-collapse: collapse;
    background-image: linear-gradient(rgba(255, 255, 255, 0.7),
            rgba(255, 255, 255, 0.7)),
        url("../Images/watermark.png");
}

.vitalsTable th,
.vitalsTable td {
    border: 1px solid rgba(0, 94, 146, 1);
    padding: 12px;
    text-align: center;
    color: rgba(0, 94, 146, 1);
}

.vitalsTable th {
    font-size: 1.5vw;
}

.vitalsTable td {
    font-size: 1.5vw;
}

.tableheading {
    color: rgba(0, 94, 146, 1);
    font-weight: 700;
    font-size: 2vw;
    margin-bottom: 2vw;
}

.ecgPlot {
    width: 70vw;
    margin: 0 auto;
}

/* YourStyles.module.css */
.table {
    width: 100%;
    border-collapse: collapse;
    /* Ensures borders are merged */
    margin-bottom: 20px;
    /* Adds space below the table */
}

.vital th,
.row td {
    width: 25%;
    height: 2vw;
    padding: 2vw;
    /* Adds padding to cells */
    text-align: left;
    font-size: 1.6vw;
    /* Align text to the left */
}

.vital th {
    background-color: #f0f0f0;
    /* Light gray background for header */
    color: rgba(0, 94, 146, 1);
    /* rgba(0, 94, 146, 1) text for the header */
    border: 2px solid rgba(0, 94, 146, 1);
    /* rgba(0, 94, 146, 1) border for the header */
}

.row td {
    border: 1px solid rgba(0, 94, 146, 1);
    /* rgba(0, 94, 146, 1) border for each row */
    color: rgba(0, 94, 146, 1);
    /* rgba(0, 94, 146, 1) text for data */
}

label {
    font-weight: bold;
    display: block;
    margin-top: 10px;
}

.pageparagh {
    font-size: 1.5vw;
}

.note {
    font-size: 1.2vw;
}